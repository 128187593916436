
import { Vue, Component, Watch } from 'vue-property-decorator';
import { mixins } from "vue-class-component";
import { Etapa } from "@/core/models/gestao";
import { EtapaService } from "@/core/services/gestao";
import { TipoEmpreendimento } from '@/core/models/cadastros';
import { TipoEmpreendimentoService } from '@/core/services/empreendimento';
 
@Component
export default class ListaEtapa extends mixins(Vue) {
  item = new Etapa();
  service = new EtapaService();
  editedItem = new Etapa();

  
  lista: any[] = [];
  tree: any [] = [];
  open: any[] = [];
  active: any[] = [];

  dialog: boolean = false;
  valid: boolean = true;
  nomePai: string = "";
  editedIndex: number = -1;

  
  titulo: string = "Etapa";
  subTitulo: string = "Lista das Etapas cadastradas para o uso no Sistema";
 
  pai: string = "";
  $refs!: { formEditedItem: HTMLFormElement };
  fieldRules: any[] = [(v: any) => !!v || "Campo obrigatório"];
 
  direction = {id: 2};

  horizontalVertical = [
    { id: 1, nome: "horizontal" },
    { id: 2, nome: "vertical" },
  ];
 
  @Watch("editedItem")
  EditedItem() {
    if (this.$refs.formEditedItem) {
      this.$refs.formEditedItem.resetValidation();
    }
  }

  close() {
    this.dialog = false;
  }

  addItem(item: Etapa) {
    let pai = Object.assign({}, this.lista.find(x => x.id === this.active[0]),item);
    this.editedItem = new Etapa();
    this.editedIndex = -1
    this.editedItem.codigo ='';
    this.editedItem.etapaPaiId = Number(pai.id === -1 ? null : pai.id);
    this.editedItem.ativo = true;
    this.nomePai = pai.codigoDescricao;
    this.dialog = true;
  }

  Listar(){ 
    this.service.TreeView().then(
        res => {
          let treeAux = [{ id: -1,codigoDescricao: 'RAIZ',children: []}];
          treeAux[0].children = res.data;
          this.tree = treeAux;                        
        },
        err => this.$swal('Aviso', err.response.data, 'error')
      );
      this.service.ListarTudo().then( 
          res => {
            let aux = {id: -1,codigoDescricao: 'RAIZ',};
            this.lista = res.data.items;
            this.lista.unshift(aux);
          },
        err => this.$swal('Aviso', err.response.data, 'error')
    );
  }

  Excluir(item: Etapa) {
    this.$swal({
      title: "Atenção!",
      text: "Tem certeza que deseja excluir o registro atual?",
      icon: "question",
      showCancelButton: true,
      confirmButtonText: "Sim",
      cancelButtonText: "Não",
      showCloseButton: true,
      showLoaderOnConfirm: true,
      preConfirm: () => {
        return this.service.Excluir(item.id).then(
          (res) => {
            if (res.status == 200) {
              return res;
            }
          },
          (err) => this.$swal("Aviso", err.response.data, "error")
        );
      },
      // @ts-ignore: Unreachable code error
      allowOutsideClick: () => !this.$swal.isLoading(),
    }).then((result:any) => {
      if (result.value) {
        this.$swal(
          "Aviso",
          result.value.data,
          result.value.status == 200 ? "success" : "warning"
        );
         this.Listar();
      }
    });
  }

  Editar(item: Etapa) {
    this.editedIndex = 1;
    let pai = this.lista.find((x) => x.id === item.etapaPaiId);
    this.editedItem = item;
    this.nomePai = pai ? pai.codigoDescricao : "RAIZ";
    this.dialog = true;
  }

  Salvar() {
    if (this.$refs.formEditedItem.validate()) {
      let modelo = this.editedItem;
      this.service.Salvar(modelo).then(
        (res) => {
          this.$swal("Aviso","Operação realizada com sucesso!",res.status == 201 || res.status == 200 ? "success" : "warning");
          if (res.status == 201 || res.status == 200) {
            this.Listar();
          }
        },
        (err) => {
          if (err.response.status == 400) {
            this.$swal("Aviso", err.response.data.title, "warning");
          } else {
            this.$swal("Aviso", err.response.data, "error");
          }
        }
      ); 
      this.close();
    }
  }

  mounted() {
    this.Listar();
   }
}
